import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const ProfileIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="currentColor" {...props}>
    <Path
      d="M15 15V13.3333C15 12.4493 14.6313 11.6014 13.9749 10.9763C13.3185 10.3512 12.4283 10 11.5 10H4.5C3.57174 10 2.6815 10.3512 2.02513 10.9763C1.36875 11.6014 1 12.4493 1 13.3333V15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={props.fill ? props.fill : "currentColor"}
    />
    <Path
      d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={props.fill ? props.fill : "currentColor"}
    />
  </Icon>
);
