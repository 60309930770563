import { Trans } from "@lingui/macro";
import React, { useEffect, useState } from "react";
import { ReactNode } from "react";

import { Button } from "@design-system/controls/Button";
import { DesignSize, WhooshVariants } from "@design-system/theme";

import { Modal, ModalParts } from "../..";
import { UI } from "./ui";

interface iConfirmationModalProps {
  titleText?: string;
  confirmationMessage?: ReactNode;
  footerContent?: ReactNode;
  noMessage?: string;
  yesMessage?: string;
  isOpen: boolean;
  emitClose: (value: boolean) => void;
  "data-cy"?: string;
}
interface iConfirmationModalState {
  _isOpen: boolean;
}
export const ConfirmationModal = (props: iConfirmationModalProps) => {
  const {
    isOpen,
    emitClose,
    titleText,
    confirmationMessage,
    footerContent,
    noMessage,
    yesMessage,
    "data-cy": dataCy,
  } = props;
  const [state, setState] = useState<iConfirmationModalState>({
    _isOpen: isOpen,
  });
  useEffect(() => {
    setState((state) => ({ ...state, _isOpen: isOpen }));
  }, [isOpen]);

  return (
    <Modal
      isOpen={state._isOpen}
      size={DesignSize.SM}
      style={{ height: "auto", top: "30%" }}
      emitClose={() => {
        emitClose(false);
        setState({ ...state, _isOpen: false });
      }}
      data-cy={dataCy}
    >
      <ModalParts.Header>{titleText}</ModalParts.Header>
      <UI.ModalForm
        onSubmit={(e) => {
          e.preventDefault();
          emitClose(true);
          setState({ ...state, _isOpen: false });
        }}
      >
        <UI.ModalBody
          style={{ height: "auto", textAlign: "center", paddingTop: "8px" }}
        >
          {confirmationMessage}
        </UI.ModalBody>
        <UI.ModalFooter>
          <Button
            data-cy="confirmation-modal-no-button"
            variant={WhooshVariants.DIM}
            type="button"
            onClick={() => {
              emitClose(false);
              setState({ ...state, _isOpen: false });
            }}
            aria-label="Confirmation Modal No Button"
          >
            {noMessage ? noMessage : <Trans>No</Trans>}
          </Button>
          {footerContent}
          <Button
            type="submit"
            data-cy="confirmation-modal-yes-button"
            aria-label="Confirmation Modal Yes Button"
          >
            {yesMessage ? yesMessage : <Trans>Yes</Trans>}
          </Button>
        </UI.ModalFooter>
      </UI.ModalForm>
    </Modal>
  );
};
