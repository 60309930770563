import { AnimatePresence } from "moti";
import React, { ReactNode, useCallback, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

import { VStack } from "../../primitive";
import ToastWrapper from "./ToastWrapper";
import { ShowToastProps } from "./types";
import { useToast } from "./useToast";

interface iToastContext {
  add: (toast: ShowToastProps) => void;
  remove: (toastId: string) => void;
}

export const ToastContext = React.createContext<iToastContext | null>(null);

type WhooshToastProviderProps = {
  children: ReactNode;
};

export const WhooshToastProvider = ({ children }: WhooshToastProviderProps) => {
  const [currentToastState, dispatch] = useReducer(toastReducer, {
    toasts: [],
  });
  const parentToastFuncs = useToast();

  const remove = useCallback(
    (id: string) => {
      dispatch({ type: "RemoveToast", id });
      parentToastFuncs.closeToast(id);
    },
    [parentToastFuncs]
  );

  const add = useCallback(
    (args: ShowToastProps) => {
      const {
        toastId,
        title,
        description,
        rightButton,
        semanticStyle,
        duration,
        testID,
      } = args;
      const id = toastId || uuidv4();
      const newToast = (
        <ToastWrapper
          {...{
            id,
            semanticStyle,
            duration,
            title,
            description,
            rightButton,
            testID,
          }}
          closeToast={remove}
        />
      );
      const toast = { id, content: newToast };
      dispatch({ type: "AddToast", toast });
      parentToastFuncs.showToast({ ...args, toastId: id });
    },
    [parentToastFuncs, remove]
  );

  return (
    <ToastContext.Provider value={{ add, remove }}>
      {children}
      <VStack position={"absolute"} left={16} bottom={24} style={{ gap: 8 }}>
        <AnimatePresence>
          {currentToastState.toasts.map((t) => t.content)}
        </AnimatePresence>
      </VStack>
    </ToastContext.Provider>
  );
};

type ToastProviderState = {
  toasts: Toast[];
};
export type Toast = {
  content: ReactNode;
  id: string;
};

type ToastActions =
  | { type: "AddToast"; toast: Toast }
  | { type: "RemoveToast"; id: string };

const toastReducer = (state: ToastProviderState, action: ToastActions) => {
  switch (action.type) {
    case "AddToast":
      //Validate id doesn't already exist ?
      const newId = action.toast.id ? action.toast.id : uuidv4();
      const newToast = { ...action.toast, id: newId };
      return { ...state, toasts: [...state.toasts, newToast] };
    case "RemoveToast": {
      if (state.toasts.some((t) => t.id === action.id)) {
        const toasts = state.toasts.filter((t) => t.id !== action.id);
        return { ...state, toasts: toasts };
      }
      return state;
    }
    default:
      return state;
  }
};
