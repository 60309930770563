import { MotiView } from "moti";
import { IIconProps } from "native-base";
import React from "react";

import { LoadingSpinnerIcon } from "../../icons_v2/general/icons/LoadingSpinnerIcon";

export const LoadingSpinner = (props: IIconProps) => {
  return (
    <MotiView
      from={{
        transform: [{ rotate: "0deg" }],
      }}
      animate={{
        transform: [{ rotate: "360deg" }],
      }}
      transition={{
        rotate: {
          loop: true,
          repeatReverse: false,
          type: "timing",
          duration: 1000,
          delay: 0,
        },
      }}
    >
      <LoadingSpinnerIcon {...props} />
    </MotiView>
  );
};
