import React, { ReactNode } from "react";

import { DesignSize, WhooshVariants } from "@/design-system/theme";

import * as UI from "./ui";
import { iIconButtonStyleProps } from "./ui";

export enum IconButtonShape {
  ROUND = "round",
  SQUARE = "square",
}

export interface iIconButtonProps {
  children?: ReactNode;
  /** Currently Ignores Size and is always 36 x 36 */
  size?: DesignSize;
  /** Default Color and Style Variants */
  variant?: WhooshVariants;
  /** Shape. Defaults to Round. Currently only supports Round */
  shape?: IconButtonShape;
  disabled?: boolean;
  tooltip?: string;
  /**
   * If true - will ignore any color styles passed to the svg icon
   * via Whoosh Variants.
   * This was added as some icons have too complex/unique a tree to
   * easily make common styles for. This is an escape hatch for those
   * instances.
   * TODO: Better solve icons.
   */
  ignoreIconStyles?: boolean;
  onClick?: () => void;
  "data-cy"?: string;
  viewOnly?: boolean;
  "aria-label"?: string;
}

const defaultButtonProps: iIconButtonStyleProps = {
  shape: IconButtonShape.ROUND,
  size: DesignSize.MD,
  variant: WhooshVariants.PRIMARY,
};

export const IconButton: React.FC<iIconButtonProps> = ({
  children,
  ...props
}) => {
  const mergedProps = { ...defaultButtonProps, ...props };
  return (
    <UI.IconButtonStyled
      data-cy={props["data-cy"]}
      {...mergedProps}
      whileTap={props.disabled ? {} : { scale: 0.92 }}
      aria-label={props["aria-label"]}
      shape={IconButtonShape.SQUARE}
    >
      {props.tooltip ? <UI.Tip>{props.tooltip}</UI.Tip> : null}
      {children}
    </UI.IconButtonStyled>
  );
};
