import { whooshPrimitives } from "./primitives";

export type WhooshPrimitiveTokens = {
  black: string;

  "grayscale-0": string;
  "grayscale-100": string;
  "grayscale-200": string;
  "grayscale-400": string;
  "grayscale-500": string;
  "grayscale-600": string;
  "grayscale-700": string;
  "grayscale-800": string;
  "grayscale-900": string;
  "grayscale-1000": string;

  "main-primary-200": string;
  "main-primary-300": string;
  "main-primary-400": string;
  "main-primary-500": string;
  "main-primary-1000": string;

  "main-secondary-500": string;
  "color-accent-blue-500": string;
  transparent: string;

  "status-error-100": string;
  "status-error-500": string;
};

export const whooshPrimitiveTokens: WhooshPrimitiveTokens = {
  transparent: "transparent",
  black: whooshPrimitives.outdated.neutrals.black,
  "grayscale-0": whooshPrimitives.grayscale[0],
  "grayscale-100": whooshPrimitives.grayscale[100],
  "grayscale-200": whooshPrimitives.grayscale[200],
  "grayscale-400": whooshPrimitives.grayscale[400],
  "grayscale-500": whooshPrimitives.grayscale[500],
  "grayscale-600": whooshPrimitives.grayscale[600],
  "grayscale-700": whooshPrimitives.grayscale[700],
  "grayscale-800": whooshPrimitives.grayscale[800],
  "grayscale-900": whooshPrimitives.grayscale[900],
  "grayscale-1000": whooshPrimitives.grayscale[1000],

  "main-primary-200": whooshPrimitives.main.primary[200],
  "main-primary-300": whooshPrimitives.main.primary[300],
  "main-primary-400": whooshPrimitives.main.primary[400],
  "main-primary-500": whooshPrimitives.main.primary[500],
  "main-primary-1000": whooshPrimitives.main.primary[1000],

  "main-secondary-500": whooshPrimitives.main.secondary[500],

  "color-accent-blue-500": whooshPrimitives.accent.blue[500],

  "status-error-100": whooshPrimitives.status.error[100],
  "status-error-500": whooshPrimitives.status.error[500],
};
