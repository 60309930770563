import styled from "styled-components/macro";

import { ModalParts } from "../..";

const ModalBody = styled(ModalParts.Body)`
  height: 0px;
  flex-grow: 1;
  flex-shrink: 1;
`;
const ModalFooter = styled(ModalParts.Footer)`
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 1px;
`;
export const UI = {
  ModalBody,
  ModalFooter,
  ModalForm,
};
